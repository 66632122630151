<template>
  <div class="m1">
    <div class="m1-h">
      <h2>用户反馈</h2>
      <div class="m1-btns">
        <el-input v-model="form.user_key_id" style="width:169px; margin-right:10px" placeholder="请输入用户ID"></el-input>
        <el-input v-model="form.phone" style="width:169px; margin-right:10px" placeholder="请输入电话"></el-input>
        <el-date-picker
          v-model="dates"
          type="daterange"
          style="margin-right:10px"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-button type="primary" @click="search()" style=" margin-right:10px">查询</el-button>
        <el-button type="primary" plain @click="reset()">重置</el-button>
      </div>
    </div>
    <el-table
      border
      :data="listData.data"
      style="width: 100%">
      <el-table-column
        prop="user_key_id"
        label="用户ID">
      </el-table-column>
      <el-table-column
        prop="nick_name"
        label="用户昵称">
      </el-table-column>
      <el-table-column
        prop="phone"
        label="电话">
      </el-table-column>
      <el-table-column
        prop="content"
        label="反馈内容">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="反馈时间">
      </el-table-column>
      <el-table-column
        label="处理">
        <template slot-scope="scope">
            <span v-if="scope.row.status == 1">已处理</span>
            <span v-else>
                <a @click="changeStatus(scope.row)" href="javascript:;">待处理</a>
                |
                <a @click="toDelete(scope.row)" href="javascript:;">删除</a>
            </span>
        </template>
      </el-table-column>
      
    </el-table>
    <div class="m1-r1">
      <div>共<span class="m1-text1">{{listData.total}}</span>条记录</div>
      <div>
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getList()"
          :current-page.sync="form.page"
          :total="listData.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  list, status, feedbackDelete
} from '@/api/feedback';
import moment from 'moment'
export default {
  name: 'index',
  data() {
    return {
      data: [],
      dates: [],
      form: {
        user_key_id: '',
        phone: '',
        begin_date: '',
        end_date: '',
        page: 1,
        page_size: 10
      },
      listData: {
          data: [],
          total: 0
      },
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.search()
  },
  methods: {
    getList () {
      this.form.begin_date = this.dates && this.dates.length > 0 ? moment(this.dates[0]).format('YYYY-MM-DD') : '';
      this.form.end_date = this.dates && this.dates.length > 0 ? moment(this.dates[1]).format('YYYY-MM-DD') : '';
      list(this.form).then(res =>{
          console.log(res)
        this.listData = res.data.data
      })
    },
    changeStatus (item) {
      status({
          ids: [item.id]
      }).then(res =>{
        this.getList()
      })
    },
    toDelete (item) {
      feedbackDelete({
          ids: [item.id]
      }).then(res =>{
        this.getList()
      })
    },
    search () {
      this.getList()
    },
    reset () {
        this.dates = []
        this.form = {
            user_key_id: '',
            phone: '',
            begin_date: '',
            end_date: '',
            page: 1,
            page_size: 10
        }
    }
  },
}
</script>
<style scoped lang="scss">
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.m1-r1{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.m1-text1{
  color: #409EFF;
}
</style>
